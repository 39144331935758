@font-face {
	font-family: 'Garamond Premier Pro Subhead';
	src: url('../fonts/GaramondPremierProSubhead-Medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
	font-display: fallback;
}

html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

html, body {
	padding: 0;
	margin: 0;
	font-size: 16px;
	background-color: #fffffa;
	text-rendering: optimizeLegibility; 
}

div {
	font-family: Garamond Premier Pro, Garamond, Hoefler Text, Baskerville, Libre Baskerville, Georgia, Palatino, Palatino Linotype, serif;
	font-size: 1.3125rem;
	font-weight: 400;
	line-height: calc(30/21);
	color: #000;
	text-shadow: 0 0 1px #fffffa;
	text-align: justify;
	text-justify: inter-word;
	font-variant-ligatures: common-ligatures;
	tab-size: 4;
	-moz-font-feature-settings: "liga", "clig";
	-webkit-font-feature-settings: "liga", "clig";
	font-feature-settings: "liga", "clig";

	min-height: 100vh;
	overflow: auto;
	padding: 0 0 80px 0;
	position: relative;
}

span{
	padding-left: 5px;
}

h1 {
	font-family: Garamond Premier Pro Subhead, Garamond, Hoefler Text, Baskerville, Libre Baskerville, Georgia, Palatino, Palatino Linotype, serif;
	font-weight: 500;
	font-size: 2em;
	text-align: center;
	line-height: 1.15;
	color: #000;
	max-width: 620px;
	margin: 80px auto 40px auto;
}

a {
	text-decoration: none;
	color: inherit;
}
a:hover {
	color: #999;
	border-bottom: 1px dotted #999;
}
a:active {
	color: #777;
}
a:link::after,

p {
	max-width: 620px;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	margin: auto;
	margin-top: 1em;
	margin-bottom: 1em;
}
p em {
	font-variant-ligatures: discretionary-ligatures;
	-moz-font-feature-settings: "dlig";
	-webkit-font-feature-settings: "dlig";
	font-feature-settings: "dlig";
}

.icon_link {
	text-align: center;
	max-width: 620px;
	margin: 2em auto;
}
.icon_link {
	position: relative;
	height: 35px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { 
	div {
		font-weight: 500;
	}
	div .diagram {
		font-weight: 400;
	}
	div .boxed {
		font-weight: 400;
	}
}

@media all and (max-width: 654px) {
	div {
		padding-left: 0.5em;
		padding-right: 0.5em;
	}
}

@media all and (max-width: 520px) {
	div {
		padding-left: 0.5em;
		padding-right: 0.5em;
		padding-top: 1px;
		overflow: unset;
		font-size: 1.125rem;
	}
	p.indent {
		padding-left: 1.75em;
	}
}

@media (prefers-color-scheme: dark) {
	body::before {
		content: "";
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: #fffffa;
		top: 0;
		left: 0;
	}
	body::before,
	body > *:not(div),
	div > *,
	div img {
		background-color: inherit;
		filter: invert(100%);
		text-shadow: 0 0 0 #000;
	}
	div {
		font-weight: 500;
	}
	a:link::after,
	a:visited::after,
	a.footnote {
		color: #003b6e;
	}
	.icon_link a::after {
		filter: invert(100%) brightness(50%) saturate(300%);
	}
}
